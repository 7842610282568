<!--
 * @LastEditors: absorbedyy@163.com
 * @LastEditTime: 2024-07-26 13:54:15
-->
<template>
  <el-dialog
    title="选择人员"
    top="5vh"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    :visible.sync="showDialog"
    width="1240px"
    class="qmDialog"
    @close="closeDialog"
  >
    <el-container>
      <!--      <CommonTree ref="CommonTree" :titleShow="false" :treeData="treeData" :defaultProps="defaultProps"-->
      <!--                  :searchTitle="searchTitle" :treeTitle="$t(`cip.plat.sys.user.title.treeTitle`)"-->
      <!--                  :loading="treeDialogLoading" :isShowdig="false" :showCheckbox="false" @getNodeClick="getNodeClick"/>-->
      <!--更新为懒加载树结构-->
      <tree-layout
        v-if="showDialog"
        :treeLoading="treeLoading"
        class="userSelectTreeLayout"
        treeTitle="组织架构"
        :tree-options="treeOptions"
        @tree-node-click="treeNodeClick"
        @include-down="includeDown"
        style="margin-right: 5px"
      ></tree-layout>
      <el-main>
        <div class="headers">
          <grid-head-layout
            ref="gridHeadLayout"
            :head-title="$t('cip.plat.sys.user.title.indexHeadTitle')"
            :search-columns="searchColumns"
            @grid-head-search="searchChange"
            @grid-head-empty="searchReset"
          >
          </grid-head-layout>
        </div>
        <grid-layout
          ref="gridLayout"
          class="userSelectGridLayout"
          :head-title="$t('cip.plat.sys.user.title.indexHeadTitle')"
          :data-total="page.total"
          @page-size-change="onLoad"
          @page-current-change="onLoad"
          @page-refresh-change="onLoad"
          :page="page"
          @gird-handle-select-click="selectionChange"
          :tableOptions="tableOptions"
          :tableData="tableData"
          :tableLoading="tableLoading"
          :gridRowBtn="gridRowBtn"
        >
        <template slot="birthday" slot-scope="{ row }">
            <span :style="itemObject(row)">
              {{getAge(row.birthday)}}
            </span>
          </template>
        </grid-layout>
      </el-main>
    </el-container>
    <span slot="footer">
      <el-button
        size="small"
        @click="closeDialog"
        v-loading="treeDialogLoading"
      >
        {{ $t(`cip.cmn.btn.celBtn`) }}
      </el-button>
      <el-button
        size="small"
        type="primary"
        @click="handleSave"
        v-loading="treeDialogLoading"
      >
        {{ $t(`cip.cmn.btn.defBtn`) }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  getDeptLazyTree,
  getDeptTree,
  getLazyListDept,
} from "@/api/system/dept";
import { getList, getUserInfo } from "@/api/system/user";
import { calculateAge } from "@/util/util";
import GridLayout from "@/views/components/layout/grid-layout";
import CommonTree from "@/views/components/com_tree";
import TreeLayout from "@/views/components/layout/tree-layout";
export default {
  components: {
    GridLayout,
    CommonTree,
    TreeLayout,
  },
  data() {
    return {
      treeLoading: true,
      showDialog: false,
      treeDialogLoading: false,
      treeReadOnlyFlag: false,
      form: {},
      title: "",
      treeData: [],
      gridRowBtn: [],
      searchTitle: "title",
      defaultProps: {
        label: "title",
        value: "key",
      },
      treeDeptId: "",
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      tableOptions: {
        menu: false,
        column: [
          {
            label: this.$t("cip.plat.sys.user.field.account"),
            prop: "account",
            cell: true,
          },
          // {
          //   label: this.$t("cip.plat.sys.user.field.tenantName"),
          //   prop: "tenantName",
          //   cell: true,
          //   hide: "",
          // },
          {
            label: this.$t("cip.plat.sys.user.field.realName"),
            prop: "realName",
            cell: true,
          },
          {
            label: '年龄',
            prop: "birthday",
            cell: true,
            slot: true
          },
          // {
          //   label: this.$t("cip.plat.sys.user.field.roleName"),
          //   prop: "roleName",
          //   cell: true,
          // },
          {
            label: this.$t("cip.plat.sys.user.field.deptName"),
            prop: "deptName",
            cell: true,
          },
          {
            label: this.$t("cip.plat.sys.user.field.isEnabled"),
            prop: "isEnabled",
            cell: true,
            dataType: "number",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=ISENBLE",
          },
          {
            label: this.$t("cip.plat.sys.user.field.userType"),
            type: "select",
            dicUrl: "/api/sinoma-system/dict/dictionary?code=user_type",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            align: "center",
            dataType: "number",
            prop: "userType",
            hide: true,
          },
        ],
        selectable: (row) => {
          // 根据条件决定是否可选中
          let findStatus = false;
          if (this.oldSelectionList.length) {
            findStatus = this.oldSelectionList.find((item) => {
              return item.userId === row.id;
            });
          }
          if (row.isEnabled == 1) {
            findStatus = true;
          }
          return !findStatus; // 如果 findStatus 是 true，则不可选中
        },
      },
      selectionList: [],
      oldSelectionList: [],
    };
  },
  computed: {
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    searchColumns() {
      return [
        {
          label: "",
          prop: "account",
          span: 8,
          placeholder:
            this.$t("cip.cmn.rule.inputWarning") +
            this.$t("cip.plat.sys.user.field.account"),
        },
        {
          label: "",
          prop: "realName",
          span: 8,
          placeholder:
            this.$t("cip.cmn.rule.inputWarning") +
            this.$t("cip.plat.sys.user.field.realName"),
        },
      ];
    },
    treeOptions() {
      let that = this;
      return {
        defaultExpandAll: false,
        nodeKey: "id",
        lazy: true,
        treeLoad: function (node, resolve) {
          that.treeLoading = true;
          const parentId = node.level === 0 ? 0 : node.data.id;
          getDeptLazyTree(parentId).then((res) => {
            that.treeLoading = false;
            resolve(
              res.data.data.map((item) => {
                return {
                  ...item,
                  leaf: !item.hasChildren,
                };
              })
            );
          });
        },
        menu: false,
        addBtn: false,
        // size: 'mini',
        props: {
          labelText: this.$t("cip.plat.sys.user.field.title"),
          label: "title",
          value: "value",
          children: "children",
        },
      };
    },
  },
  props: {
    userSure: { // 为true时，点击确认不关闭弹窗，需在父组件手动关闭
      type: Boolean,
      default: false
    },
    params:{
      type:Object,
      default: ()=>{}
    },
  },
  methods: {
    itemObject(item) {
          let age =this.getAge(item.birthday) || 0
          if (
            (item.sex == 1 && age >= 50) ||
            age >= 60 || age < 18
          ) {
            return { color: "red" };
          } else {
            return { color: "black" };
          }
    },
    init(data, type) {
      this.showDialog = true;
      this.selectedType = type; // 如果type == ‘contingency’ 表示从应急管理中打开的人员选择弹框 需要设置选择个数
      this.oldSelectionList = data;
      this.$nextTick(() => {
        //暂时注释用data里的数据
        // this.getTreeData();
        this.onLoad(this.page);
      });
    },
    getTreeData() {
      this.treeDialogLoading = true;
      // getDeptTree().then((res) => {
      //   this.treeDialogLoading = false
      //   this.treeData = res.data.data;
      // });
      getUserInfo().then((res) => {
        getLazyListDept(1, 9999, res.data.data.deptId, {}).then((res) => {
          const data = res.data.data;
          let treeDate = [];
          data.records.forEach((item) => {
            let cc = {
              title: item.deptName,
              value: item.id,
              id: item.id,
              key: item.id,
              hasChildren: item.hasChildren,
            };
            treeDate.push(cc);
          });
          this.treeData = treeDate;
          this.treeDialogLoading = false;
        });
      });
    },
    includeDown(e) {
      this.page.currentPage = 1;
      this.query.isInclude = e;
      this.onLoad(this.page);
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      // params.personnelTypes = 1
      getList({
        ...params,...this.params,...this.$refs.gridHeadLayout.searchForm,
        size: page.pageSize,
        current: page.currentPage,
        deptId:this.treeDeptId,
        isInclude:true,
      }).then((res) => {
        const data = res.data.data;
        this.$refs.gridLayout.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
      });
    },
    selectionChange(list) {
      // 如果选中列表的值大于1个
      if (this.selectedType == "contingency" && list.length > 1) {
        // 将列表中的第一个元素取出
        const preVal = list.shift();
        // 将该选项设置为未选中
        this.$refs.gridLayout.$refs.grid.toggleRowSelection(preVal, false);
      }
      this.selectionList = list;
    },
    getNodeClick(data) {
      this.treeDeptId = data.id;
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    searchChange() {
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    searchReset() {
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    /**
     * 部门树结构点击事件
     * @param node
     */
    treeNodeClick(node) {
      this.treeDeptId = node.id;
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    // 新增左侧树节点
    handleTreeNodeSave() {
      this.$refs.addForm.submit();
    },
    handleSave() {
      if(!this.userSure)this.showDialog = false;
      this.$emit("callBack", this.selectionList);
      this.$refs.gridHeadLayout.searchForm = {};
      this.treeDeptId = "";
      // this.$refs.CommonTree.filterText = '' // 因为其他同事将html已经被注释，这个不注释报错 故 注释
    },
    closeDialog() {
      this.$refs.gridHeadLayout.searchForm = {};
      this.treeDeptId = "";
      // this.$refs.CommonTree.filterText = ''
      this.showDialog = false;
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .userSelectGridLayout .avue-crud .el-table {
  height: 50vh !important;
  max-height: 50vh !important;
  box-sizing: border-box;
}
::v-deep .userSelectTreeLayout .el-tree {
  height: 50vh !important;
  max-height: 50vh !important;
  overflow-y: scroll;
}
</style>
